import { ASSET_BASE_URL, HERO_PATTERN_URL } from "@/constants/assets";
import colors from "@/constants/colors";
import { HERO } from "@/constants/home.constants";
import SectionLayout from "@/layout/SectionLayout";
import { scrollTo } from "@/utils";
import { fadeIn } from "@/utils/motions";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Typography, TypographyProps } from "antd";
import { motion } from "framer-motion";
import styled from "styled-components";
import HeroGraph from "./HeroGraph";

// center flex
const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
  margin-top: -64px; // offset navbar
  z-index: 10;
`;

interface HeroPatternWrapperProps {
  bottom?: boolean;
}

export const HeroPatternWrapper = styled.div<HeroPatternWrapperProps>`
  position: absolute;
  ${({ bottom }) =>
    bottom
      ? `
      bottom: 0;
      left: 0;
      z-index: -1;
      transform: translateX(-50%) rotate(180deg);
    `
      : `
      top: 0;
      right: 0;
      z-index: -1;
      transform: translateX(50%) ;
      margin-right: -4rem;
    `}

  user-select: none;

  @media (max-width: 768px) {
    display: none;
  }
  display: block;
`;

interface HeroTitleProps extends TypographyProps {
  size: "small" | "medium" | "large";
}

// styled hero title with responsive font size
export const StyledHeroTitle = styled(motion<HeroTitleProps>(Typography.Title))`
  

  ${({ size }) =>
    size === "small"
      ? `
    font-size: clamp(1rem, 2vw, 3rem) !important;
  `
      : size === "large"
      ? `
    font-size: clamp(2rem, 4vw, 6rem) !important;
      `
      : size === "medium"
      ? `font-size: clamp(1.5rem, 3vw, 4rem) !important;`
      : "font-size: clamp(2rem, 4vw, 6rem) !important;"}
}

`;

interface MouseButtonProps {
  addFilter?: boolean;
  height?: string;
  width?: string;
  animation?: boolean;
}
const MouseButton = styled.div<MouseButtonProps>`
  // use height and width from props or default to 40px
  height: ${(props) => props.height || "40px"};
  width: ${(props) => props.width || "40px"};
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  ${(props) =>
    props.animation &&
    `
    animation: mouse 1.5s infinite ease-in-out;
      `}
  cursor: pointer;

  img {
    ${(props) =>
      props.addFilter &&
      `
      filter: invert(1);
    `}

    user-select: none;

    height: 100%;
    width: 100%;
  }
`;

// create svg wrapper. hide it on mobile screens. show it on desktop screens
// it should be absolute top right of the screen

export const StyledHeroSubtitle = styled(motion(Typography.Text))`
  color: ${colors.secondaryWhite};
  font-size: clamp(1rem, 2vw, 1.5rem);
`;

const Animatedbutton = styled(motion(Button))``;

const Hero = () => {
  // create scrollDown function with useCallback.. it should scroll down to "our-mission" section smoothly

  return (
    <SectionLayout
      backgroundElement={
        <>
          <HeroPatternWrapper>
            <img src={HERO_PATTERN_URL} alt="none" />
          </HeroPatternWrapper>
          <HeroPatternWrapper bottom>
            <img src={HERO_PATTERN_URL} alt="none" />
          </HeroPatternWrapper>
          {/* <BgImage /> */}
        </>
      }
    >
      <StyledRow>
        <Col xs={24} lg={12}>
          <Space size={"large"} direction="vertical">
            <div>
              {HERO.words.map(
                ({ word, id, highlight }) =>
                  word && (
                    <StyledHeroTitle
                      // variants={fadeIn("right", "tween", 0.1 * (idx + 1), 1)}
                      key={id}
                      // @ts-ignore
                      className={`${
                        highlight ? "highlight" : "heading"
                      } mb-reset mt-reset`}
                    >
                      {word}
                    </StyledHeroTitle>
                  )
              )}
            </div>

            <StyledHeroSubtitle
            // variants={fadeIn("top", "tween", 0.3, 1)}
            >
              {HERO.subtitle}
            </StyledHeroSubtitle>
            <Animatedbutton
              size="large"
              // variants={fadeIn("left", "tween", 0.2, 0.4)}
              onClick={() => scrollTo("our-mission")}
              icon={<ArrowRightOutlined />}
              type="primary"
            >
              {HERO.button}
            </Animatedbutton>
          </Space>
        </Col>
        <Col xs={0} lg={12}>
          <HeroGraph />
        </Col>
      </StyledRow>

      <MouseButton animation onClick={() => scrollTo("our-mission")}>
        <motion.img
          variants={fadeIn("up", "tween", 0.3, 1)}
          alt="mouse"
          src={
            "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/company-assets/f6ecae19-15c9-4651-8acd-0779040647e1/5c9aa0c9-7caa-4a00-ad70-4cac5cb47ec1/VECTORS/5c9aa0c9-7caa-4a00-ad70-4cac5cb47ec1/83fa4641-42a9-479a-a4ae-19f3460bf086?1729903423956"
          }
        />
      </MouseButton>
    </SectionLayout>
  );
};

export default Hero;
