export const ASSET_BASE_URL = "https://zoiqclients.s3.amazonaws.com/assets";
export const VIDEO_BACK = `https://media.discordapp.net/attachments/941977478421946388/1164224217949151283/image.png?ex=65426f4b&is=652ffa4b&hm=e5553296447193289536f3a9a22d94e079798710b479f3aef12e7d7da01104e8&=&width=1476&height=822`;
export const VIDEO_LOADING = `https://media.discordapp.net/attachments/941977478421946388/1164224217949151283/image.png?ex=65426f4b&is=652ffa4b&hm=e5553296447193289536f3a9a22d94e079798710b479f3aef12e7d7da01104e8&=&width=1476&height=822`;
export const VIDEO_POSTER = `https://media.discordapp.net/attachments/941977478421946388/1165017789443088434/image.png?ex=6545525d&is=6532dd5d&hm=c5e980660a756c85e22b4cc31b0d2d03a05c5ece2bb75dc5a0e019e667d84ac2&=&width=1047&height=585`;
export const NO_FILE_ATTACHED =
  "https://media.discordapp.net/attachments/941977478421946388/1164225440647495720/image.png?ex=6542706f&is=652ffb6f&hm=35f8aabb4847bfd5426f194e11d4e2a06ecc81123c98c6a9a59497afd5680729&=&width=1471&height=829";
export const VIDEO_BACK_ERROR = `${ASSET_BASE_URL}/video-cover.png`;

export const OUR_MISSION_VIDEO_URL =
  "https://d3gdl9xmftisam.cloudfront.net/public/videos/zoiqintro9.mp4";

export const ZOIQ_ISOLATED_LOGO =
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/company-assets/0af93b60-5b7f-4946-a7c5-94874d1d95f8/ORGANIZATION/2c486c2b-361d-412b-85fb-aac00af7c34f?1699025210563";

export const zoiqLogo =
  "https://zoiqassetsbucket200938-staging.s3.us-east-1.amazonaws.com/public/company-assets/f6ecae19-15c9-4651-8acd-0779040647e1/7b0a658b-fd20-40fe-a662-b00b34a8ef30/ORGANIZATION/7b0a658b-fd20-40fe-a662-b00b34a8ef30/b12facdf-1a7d-4828-9f26-c9ddd004870b?1729900049605";
export const HERO_PATTERN_URL = `${ASSET_BASE_URL}/pattern1.png`;
export const VIDEO_PATTERN_URL = `${ASSET_BASE_URL}/pattern2.png`;

export const NAVBAR_SIZE = 64;

export const videoAccept = ", .gif, .mp4, .mov, .avi";
export const audioAccept = ", .mp3, .wav, .ogg, .m4a, .aac, .flac, .wma";
export const imgAccept = ".png, .svg, .jpeg, .webp, .jpg ";
export const imgVideoAudioAccept = ""
  .concat(imgAccept)
  .concat(videoAccept)
  .concat(audioAccept);

export const docAccept = ".pdf, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt";

export const docExtended = docAccept.concat(
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
);
export const vidExtended = videoAccept.concat(
  "video/x-msvideo, video/mpeg, video/ogg, video/webm, video/3gpp, video/3gpp2, video/quicktime, video/x-ms-wmv, video/x-flv, video/x-matroska, video/mp4"
);
